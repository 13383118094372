<template>
    <div class="block">
        <el-tree
            ref="tree"
            :data="list"
            :show-checkbox="showCheckbox"
            node-key="id"
            default-expand-all
            check-strictly
            :default-expanded-keys="expandedKeys"
            :default-checked-keys="checkedKeys"
            @check-change="handleCheckChange"
            :expand-on-click-node="false">
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span v-if="editable">
                    <el-button type="text" size="mini" @click="() => onEdit(data)">编辑</el-button>
                </span>
            </span>
        </el-tree>
        <el-dialog :title="title" v-if="dialogVisible" :close-on-press-escape="false" show-close
            :close-on-click-modal="true" :visible="dialogVisible" width="600px"
            @close="onCloseDialog()"
        >
            <edit ref="edit" :data="row" :nodes="list" btn-name="修改" :visible="dialogVisible" @close-edit-modal="onCloseDialog(true)" @re-load="reLoad" />
        </el-dialog>
    </div>
</template>
<script>
import edit from "./edit";

export default {
    components: {
        edit
    },
    props: {
        url: {
            type: String,
            default: ""
        },
        showCheckbox: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: []
        },
        expandedKeys: {
            type: Array,
            default: []
        },
        checkedKeys: {
            type: Array,
            default: []
        }
    },
    data () {
        return {
            list: [],
            row: null,
            dialogVisible: false,
            checkedData: new Set(),
            checkedDataIds: new Set()
        }
    },
    watch: {
        data: function (v1, v2) {
            this.list = v1;

            const checkedKeysSet = new Set(this.checkedKeys);

            this.setCheckedData(checkedKeysSet, v1);
        }
    },
    created () {
        console.log("node-tree-index");
        this.list = this.data;
    },
    methods: {
        onEdit (row) {
            this.row = row;
            this.dialogVisible = true;
            this.title = "修改权限节点配置";
        },
        onAppend(data) {
            const newChild = { id: id++, label: 'testtest', children: [] };
            if (!data.children) {
                this.$set(data, 'children', []);
            }
            data.children.push(newChild);
        },
        onDelete(node, data) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
        },
        async onCloseDialog (ignore) {
			// 通过 emit 发送的关闭请求一律通过
			// 如果点击 close 图标则一律直接关闭
			if (ignore || !this.$refs.edit || await this.$refs.edit.onClosed()) {
				// 设置 visable 后,会重新触发 close 所以需要先 remove edit
				delete this.$refs.edit;
                this.dialogVisible = false;
			}
        },
        handleCheckChange(data, checked, indeterminate) {
            if (checked) {
                this.checkedData.add(data.value);
            } else {
                this.checkedData.delete(data.value);
            }
        },
        setCheckedData (configs, list) {
            for (const item of list) {
                if (configs.has(item.id)) {
                    this.checkedData.add(item.value);
                }
                this.setCheckedData(configs, item.children);
            }
        },
        reLoad () {
            this.$emit("re-load");
        },
        getValue () {
            return Array.from(this.checkedData);
        },
        addVal (list) {
            for (const obj of list) {
                this.checkedData.add(obj.value);
                this.checkedDataIds.add(obj.id);
                if (obj.children) {
                    this.addVal(obj.children);
                }
            }
        },
        selectAll (isSelectAll) {
            this.checkedDataIds = new Set();
            this.checkedData = new Set();
            if (isSelectAll) {
                this.addVal(this.list);
            }
            let checkedKeys = Array.from(this.checkedDataIds);
            this.$refs.tree.setCheckedKeys(checkedKeys);
        }
    }
}
</script>