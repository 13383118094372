<template>
    <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="上位节点" prop="upperNode">
                <el-select size="small" clearable v-model="form.upperNode" style="width: 100%;" placeholder="请选择一个上位节点">
                    <el-option v-for="node in nodes" in :key="node.id" :label="node.label" :value="node.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="节点名称" prop="nodeName">
                <el-input v-model="form.nodeName" clearable size="small" placeholder="请输入节点名称"></el-input>
            </el-form-item>
            <el-form-item label="节点值" prop="nodeValue">
                <el-input v-model="form.nodeValue" clearable size="small" placeholder="请输入节点值"></el-input>
            </el-form-item>
            <el-form-item style="text-align: right;">
                <el-button type="primary" size="small" @click="onSubmit">{{ btnName }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { add, edit } from "@/api/menus";

export default {
    props: {
        btnName: {
            type: String,
            default: "添加"
        },
        nodes: {
            type: Array,
            default: []
        },
        data: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            rules: {
                nodeName:[
                    { required: true, message: '请输入节点名称', trigger: 'blur' }
                ],
                nodeValue: [
                    { required: true, message: '请输入节点值', trigger: 'blur' }
                ]
            },
            form: {
                upperNode: "",
                nodeName: "",
                nodeValue: ""
            }
        }
    },
    created () {
        if (this.data !== null) {
            this.form.upperNode = this.data.upper;
            this.form.nodeName = this.data.label;
            this.form.nodeValue = this.data.value;
        }
    },
    methods: {
        async onClosed () {
            return await $utils.closeDialogConfirm(this);
        },
        onSubmit () {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return;
                }

                const data = Object.assign({}, this.form);

                if (this.data) {
                    data.id = this.data.id;
                }

                const method = this.data ? edit : add;
                await method(data);

                this.form = {
                    upperNode: "",
                    nodeName: "",
                    nodeValue: ""
                }

                this.$emit("re-load");
                this.$emit("close-edit-modal");
            });
        }
    }
}
</script>