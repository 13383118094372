var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上位节点", prop: "upperNode" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "请选择一个上位节点",
                  },
                  model: {
                    value: _vm.form.upperNode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "upperNode", $$v)
                    },
                    expression: "form.upperNode",
                  },
                },
                _vm._l(_vm.nodes, function (node) {
                  return _c("el-option", {
                    key: node.id,
                    attrs: { in: "", label: node.label, value: node.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点名称", prop: "nodeName" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "small",
                  placeholder: "请输入节点名称",
                },
                model: {
                  value: _vm.form.nodeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nodeName", $$v)
                  },
                  expression: "form.nodeName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点值", prop: "nodeValue" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "small",
                  placeholder: "请输入节点值",
                },
                model: {
                  value: _vm.form.nodeValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nodeValue", $$v)
                  },
                  expression: "form.nodeValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.btnName))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }