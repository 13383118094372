import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`menus?${rand}`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.post(`menus/add?${rand}`, data);
}
// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.post(`menus/update?${rand}`, data);
}

export default {
    list,
    add, 
    edit
}