var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.list,
          "show-checkbox": _vm.showCheckbox,
          "node-key": "id",
          "default-expand-all": "",
          "check-strictly": "",
          "default-expanded-keys": _vm.expandedKeys,
          "default-checked-keys": _vm.checkedKeys,
          "expand-on-click-node": false,
        },
        on: { "check-change": _vm.handleCheckChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c("span", { staticClass: "custom-tree-node" }, [
                _c("span", [_vm._v(_vm._s(node.label))]),
                _vm.editable
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: { click: () => _vm.onEdit(data) },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog()
                },
              },
            },
            [
              _c("edit", {
                ref: "edit",
                attrs: {
                  data: _vm.row,
                  nodes: _vm.list,
                  "btn-name": "修改",
                  visible: _vm.dialogVisible,
                },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog(true)
                  },
                  "re-load": _vm.reLoad,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }